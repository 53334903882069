<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>New Sub Territory</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          to="/setup/territories"
        >
          <v-icon
            left
            dark
          >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              readonly
              filled
              outlined
              dense
              v-model="dataItem.descript"
              label="Parent Territory"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              dense
              v-model="descript"
              label="Sub Territory"
            ></v-text-field>
          </v-col>

          <v-col cols="10">
            <v-btn
              color="accent"
              @click="save"
              :loading="loader"
            >
              <v-icon left>mdi-content-save</v-icon>Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    descript: null,
    loader: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getTerritory",
      immediate: true,
    },
  },
  methods: {
    getTerritory(val) {
      const self = this;
      this.$store
        .dispatch("get", `/territories/${val}`)
        .then((res) => {
          self.dataItem = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save() {
      const parent = this.dataItem.id;
      const desc = this.descript;
      const data = {
        parent: parent,
        descript: desc,
      };
      this.loader = true;
      const url = "/territories";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$router.push("/setup/territories");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>